import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-kpi-control',
  templateUrl: './kpi-control.component.html',
  styleUrls: ['./kpi-control.component.scss']
})
export class KpiControlComponent {
  @Input() public title: string;
  @Input() public value: string;
  @Input() public unit: string;
  @Input() public kpiStatus: 'warning' | 'negative';

  public getKpiStatusStyleClass(): string {
    switch (this.kpiStatus) {
      case 'negative':
        return 'no-pallets--negative';
      case 'warning':
        return 'no-pallets--warning';
      default:
        return '';
    }
  }

  public getKpiStatusStyleIconClass(): string {
    switch (this.kpiStatus) {
      case 'negative':
        return 'icon-error-solid';
      case 'warning':
        return 'icon-warning-solid';
      default:
        return '';
    }
  }
}
