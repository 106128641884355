import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SharedModule } from '@pt/shared/shared.module';
import { HeaderComponent, MainMenuComponent } from '@pt/shell/components';
import { AsideComponent } from '@pt/shell/components/aside/aside.component';
import { ShellRoutingModule } from '@pt/shell/shell-routing.module';
import { ShellComponent } from './containers/shell/shell.component';
import { ActiveOrderPanelComponent } from './components/aside/active-order-panel/active-order-panel.component';
import { NgxLetDirectiveModule } from 'ngx-let-directive';

@NgModule({
  declarations: [ShellComponent, MainMenuComponent, HeaderComponent, AsideComponent, ActiveOrderPanelComponent],
  exports: [ShellComponent],
  imports: [
    SharedModule,

    // Routing import
    ShellRoutingModule,

    // 3rd party Modules
    NgxLetDirectiveModule
  ]
})
export class ShellModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: ShellModule
  ) {
    if (parentModule) {
      throw new Error('ShellModule is already loaded. Import it in the AppModule only.');
    }
  }
}
