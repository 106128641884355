import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CreatingDummyFormComponent } from './creating-dummy-form/creating-dummy-form.component';
import { KpiControlComponent } from './kpi-control/kpi-control.component';
import { MaterialStatusComponent } from './material-status/material-status.component';
import { PalletKpiComponent } from './pallet-kpi/pallet-kpi.component';
import { PalletNumberComponent } from './pallet-number/pallet-number.component';
import { StatusFlagComponent } from './status-flag/status-flag.component';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { FormComponentsModule } from '../form-components/form-components.module';
import { DismountButtonComponent } from './dismount-button/dismount-button.component';
import { MainMenuNavigationModule } from '../main-menu-navigation/main-menu-navigation.module';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { PalletIndicatorComponent } from './pallet-indicator/pallet-indicator.component';
import { MountMaterialStatusComponent } from './mount-material-status/mount-material-status.component';
import { QualityStatusComponent } from './quality-status/quality-status.component';

@NgModule({
  declarations: [
    PalletKpiComponent,
    PalletNumberComponent,
    PalletIndicatorComponent,
    StatusFlagComponent,
    KpiControlComponent,
    CreatingDummyFormComponent,
    MaterialStatusComponent,
    MountMaterialStatusComponent,
    DismountButtonComponent,
    QualityStatusComponent
  ],
  exports: [
    PalletKpiComponent,
    PalletNumberComponent,
    PalletIndicatorComponent,
    StatusFlagComponent,
    KpiControlComponent,
    CreatingDummyFormComponent,
    MaterialStatusComponent,
    MountMaterialStatusComponent,
    DismountButtonComponent,
    QualityStatusComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    InputTextareaModule,
    FormComponentsModule,
    DirectivesModule,
    PipesModule,
    CheckboxModule,
    ButtonModule,
    TranslateModule,
    MainMenuNavigationModule,
    FormsModule
  ]
})
export class ContainerComponentsModule {}
