<lib-container-pallet-template [textLabel]="'DISMOUNT_CONTAINER_FORM.LABEL' | translate">
  <!-- <lib-material-status
    [condensed]="true"
    [title]="'DISMOUNT_CONTAINER_FORM.ACTIVE_MATERIAL' | translate"
    [styleClass]="MATERIAL_STATUS.RUNNING"
    [article]="activeMaterial"
  ></lib-material-status> -->
  <lib-pallet-number
    *libContainerPalletKpi
    [palletNumber]="dismountingPalletInformation?.materialSequence"
    [classification]="dismountingPalletInformation?.article?.classification"
  ></lib-pallet-number>
  <lib-pallet-kpi
    *libContainerPalletKpi
    [kpiLabel]="'DISMOUNT_CONTAINER_FORM.SSCC' | translate"
    [kpiValue]="dismountingPalletInformation?.ssccCode"
    [isSsccCodeShort]="true"
  ></lib-pallet-kpi>
  <lib-pallet-kpi
    *libContainerPalletKpi
    [kpiLabel]="'DISMOUNT_CONTAINER_FORM.MOUNTED' | translate"
    [kpiValue]="dismountingPalletInformation?.mounted | formatQuantity: false"
  ></lib-pallet-kpi>
  <lib-pallet-kpi
    *libContainerPalletKpi
    [kpiLabel]="'DISMOUNT_CONTAINER_FORM.WHITE_WASTE' | translate"
    [kpiValue]="dismountingPalletInformation?.whiteWaste | formatQuantity: false"
  ></lib-pallet-kpi>
  <ng-container *ngIf="dismountingPalletInformation?.consumed?.value">
    <lib-pallet-kpi
      *libContainerPalletKpi
      [kpiLabel]="'DISMOUNT_CONTAINER_FORM.CONSUMED' | translate"
      [kpiValue]="dismountingPalletInformation?.consumed | formatQuantity: false"
    ></lib-pallet-kpi>
  </ng-container>
  <lib-dismounting-details [dismountingInfo]="dismountingPalletInformation"></lib-dismounting-details>
  <ng-container [formGroup]="dismountForm">
    <lib-pallet-quantity-calculation
      *libContainerPalletForm
      [formUnitLabel]="'DISMOUNT_CONTAINER_FORM.RETURN_QUANTITY' | translate"
      [article]="article"
      [bomUnitId]="dismountingPalletInformation.mounted?.unitId"
      [bomUnitFactor]="dismountingPalletInformation.bomUnitFactor"
      formControlName="returnSheets"
      [disabled]="!allowQuantityChange"
      [inventoryQuantity]="dismountingPalletInformation?.inventoryGoodQuantity"
    ></lib-pallet-quantity-calculation>
    <ng-container *libContainerPalletForm>
      <div class="row">
        <div class="col-5 label">
          {{ 'DISMOUNT_CONTAINER_FORM.PRINT_LABEL' | translate }}
        </div>
        <div class="col-7">
          <p-checkbox binary="true" formControlName="printLabel"></p-checkbox>
        </div>
      </div>
    </ng-container>
  </ng-container>
</lib-container-pallet-template>
