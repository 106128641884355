/* eslint-disable */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChronosSharedConfiguration {
  coreRootUrl: string;
  pandaRootUrl: string;
  liveRootUrl: string;
  signalrBaseUrl: string;
}

export interface ChronosSharedConfigurationInterface {
  coreRootUrl?: string;
  pandaRootUrl?: string;
  liveRootUrl?: string;
  signalrBaseUrl?: string;
}
