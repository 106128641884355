import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizeService } from './authorize.service';
import { tap } from 'rxjs/operators';
import { applicationPaths, queryParameterNames } from './api-authorization.constants';

@Injectable()
export class AuthorizeGuard implements CanActivate, CanActivateChild {
  constructor(private authorize: AuthorizeService, private router: Router) {}

  public canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authorize.isAuthenticated().pipe(
      tap((isAuthenticated) => {
        this.handleAuthorization(isAuthenticated, state);
      })
    );
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(childRoute, state);
  }

  private handleAuthorization(isAuthenticated: boolean, state: RouterStateSnapshot) {
    if (!isAuthenticated) {
      this.router.navigate(applicationPaths.LoginPathComponents, {
        queryParams: {
          [queryParameterNames.ReturnUrl]: state.url
        }
      });
    }
  }
}
