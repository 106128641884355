import { Component, Input, ChangeDetectionStrategy, SimpleChange, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'lib-pallet-number',
  templateUrl: './pallet-number.component.html',
  styleUrls: ['./pallet-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PalletNumberComponent implements OnInit, OnChanges {
  @Input() public palletNumber: number;
  @Input() public classification: string;
  @Input() public useContainer = false;

  public palletType: string;

  public ngOnInit() {
    this.palletType = this.getPalletType();
  }

  public ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes.classification && changes.classification.currentValue !== changes.classification.previousValue) {
      this.palletType = this.getPalletType();
    }
  }

  public getPalletType() {
    if (this.classification === 'Reel') {
      return 'roll';
    } else if (this.classification === 'Sheet' || this.classification === 'Product') {
      return 'pallet';
    } else {
      return 'container';
    }
  }
}
