/* tslint:disable */
type PatternTrackType = 'Track' | 'ProcessingStation';
module PatternTrackType {
  export const TRACK: PatternTrackType = 'Track';
  export const PROCESSING_STATION: PatternTrackType = 'ProcessingStation';
  export function values(): PatternTrackType[] {
    return [TRACK, PROCESSING_STATION];
  }
}

export { PatternTrackType };
