<div *ngIf="palletNumber; else noPalletNumber" class="pallet-amount">
  <div>
    <i *ngIf="palletType === 'pallet'" class="pallet-amount__icon icon-pallet"></i>
    <i *ngIf="palletType === 'roll'" class="pallet-amount__icon icon-roll"></i>
    <i *ngIf="palletType === 'container'" class="pallet-amount__icon icon-container"></i>
  </div>
  <div class="pallet-amount__amount">{{ palletNumber | number }}</div>
</div>

<ng-template #noPalletNumber>
  <div *ngIf="useContainer; else noPallet" class="pallet-amount">
    <div>
      <i class="pallet-amount__icon icon-container"></i>
    </div>
    <div class="pallet-amount__amount">—</div>
  </div>
  <ng-template #noPallet>
    <div class="pallet-amount">
      <div>
        <i class="pallet-amount__icon icon-running-no-pallet"></i>
      </div>
      <div class="pallet-amount__amount">P</div>
    </div>
  </ng-template>
</ng-template>
