import { ArticleDescription, Container, MountedMaterialStatus, PrimaryMaterialMounted, Quantity } from 'chronos-core-client';
import { MountedMaterial } from '../../../models';

export interface DismountingPalletInformation {
  ssccCode?: string;
  mounted: Quantity;
  consumed?: Quantity;
  whiteWaste?: Quantity;
  initialQuantity: Quantity;
  grammage?: number;
  thickness?: number;
  internalBatchId: string;
  externalBatchId: string;
  mountedMaterialId: number;
  mountedMaterialStatus: MountedMaterialStatus;
  mountedTime: Date;
  mountedUser: string;
  materialSequence?: number;
  mountedTo?: string;
  container?: Container;
  printLabel: boolean;
  runId?: number;
  workCenterId?: number;
  bomUnitFactor: number;
  article?: ArticleDescription;
  virtualContainerInfo?: string;
  reason?: string;
  inventoryGoodQuantity?: Quantity;
}

export class DismountingPalletInformationMapper {
  public static mapFrom(
    mountedContainer: PrimaryMaterialMounted | MountedMaterial,
    articleDescription: ArticleDescription
  ): DismountingPalletInformation {
    return {
      article: mountedContainer.article,
      mounted: mountedContainer.mountedQuantity,
      consumed: mountedContainer.consumedQuantity,
      ssccCode: mountedContainer.ssccCode,
      whiteWaste: mountedContainer.whiteWasteQuantity,
      internalBatchId: mountedContainer.container.internalBatchId,
      externalBatchId: mountedContainer.container.externalBatchId,
      mountedMaterialId: mountedContainer.mountedMaterialId,
      mountedMaterialStatus: mountedContainer.mountedMaterialStatus,
      initialQuantity: mountedContainer.initialQuantity,
      mountedTime: new Date(mountedContainer.mountedTime),
      mountedUser: mountedContainer.mountedUser,
      materialSequence: mountedContainer.materialSequence,
      mountedTo: mountedContainer.mountedTo,
      grammage: articleDescription.grammage,
      thickness: articleDescription.thickness,
      printLabel: articleDescription.labelAutoPrint,
      runId: null,
      bomUnitFactor: mountedContainer.container.bomUnitFactor,
      virtualContainerInfo: mountedContainer.virtualContainerInfo,
      reason: mountedContainer.container.virtualContainerReason,
      container: mountedContainer.container,
      inventoryGoodQuantity: mountedContainer.container.inventoryGoodQuantity
    };
  }
}
