/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { QaHistoryCheckEntry } from '../models/qa-history-check-entry';
import { CreateNonConformanceEntry } from '../models/create-non-conformance-entry';
import { FinishedGoodArticleItem } from '../models/finished-good-article-item';
import { NonConformanceItem } from '../models/non-conformance-item';
import { NonConformanceModel } from '../models/non-conformance-model';
import { CreateNonConformance } from '../models/create-non-conformance';
@Injectable({
  providedIn: 'root'
})
class QualityHistoryService extends __BaseService {
  static readonly checkQaHistoryPath = '/api/v1/QualityHistory/{productionOrderId}/Check';
  static readonly createNonConformancePath = '/api/v1/QualityHistory/{productionOrderId}/CreateNonConformance';
  static readonly getFinishedGoodArticlesPath = '/api/v1/QualityHistory/{workCenterId}/GetFinishedGoodArticles/{loadOnlyForActiveOrder}';
  static readonly getNonConformanceOverviewPath = '/api/v1/QualityHistory/{workCenterId}/GetNonConformanceOverview/{articleId}';
  static readonly getNonConformancePath = '/api/v1/QualityHistory/{nonConformanceId}/GetNonConformance';
  static readonly getNonConformance2Path = '/api/v1/QualityHistory/GetCreateNonConformance';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `QualityHistoryService.CheckQaHistoryParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  checkQaHistoryResponse(params: QualityHistoryService.CheckQaHistoryParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/QualityHistory/${encodeURIComponent(String(params.productionOrderId))}/Check`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `QualityHistoryService.CheckQaHistoryParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  checkQaHistory(params: QualityHistoryService.CheckQaHistoryParams): __Observable<null> {
    return this.checkQaHistoryResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `QualityHistoryService.CreateNonConformanceParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  createNonConformanceResponse(params: QualityHistoryService.CreateNonConformanceParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/QualityHistory/${encodeURIComponent(String(params.productionOrderId))}/CreateNonConformance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `QualityHistoryService.CreateNonConformanceParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  createNonConformance(params: QualityHistoryService.CreateNonConformanceParams): __Observable<null> {
    return this.createNonConformanceResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `QualityHistoryService.GetFinishedGoodArticlesParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `loadOnlyForActiveOrder`:
   */
  getFinishedGoodArticlesResponse(
    params: QualityHistoryService.GetFinishedGoodArticlesParams
  ): __Observable<__StrictHttpResponse<Array<FinishedGoodArticleItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/QualityHistory/${encodeURIComponent(String(params.workCenterId))}/GetFinishedGoodArticles/${encodeURIComponent(
          String(params.loadOnlyForActiveOrder)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FinishedGoodArticleItem>>;
      })
    );
  }
  /**
   * @param params The `QualityHistoryService.GetFinishedGoodArticlesParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `loadOnlyForActiveOrder`:
   */
  getFinishedGoodArticles(params: QualityHistoryService.GetFinishedGoodArticlesParams): __Observable<Array<FinishedGoodArticleItem>> {
    return this.getFinishedGoodArticlesResponse(params).pipe(__map((_r) => _r.body as Array<FinishedGoodArticleItem>));
  }

  /**
   * @param params The `QualityHistoryService.GetNonConformanceOverviewParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `articleId`:
   */
  getNonConformanceOverviewResponse(
    params: QualityHistoryService.GetNonConformanceOverviewParams
  ): __Observable<__StrictHttpResponse<Array<NonConformanceItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/QualityHistory/${encodeURIComponent(String(params.workCenterId))}/GetNonConformanceOverview/${encodeURIComponent(
          String(params.articleId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<NonConformanceItem>>;
      })
    );
  }
  /**
   * @param params The `QualityHistoryService.GetNonConformanceOverviewParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `articleId`:
   */
  getNonConformanceOverview(params: QualityHistoryService.GetNonConformanceOverviewParams): __Observable<Array<NonConformanceItem>> {
    return this.getNonConformanceOverviewResponse(params).pipe(__map((_r) => _r.body as Array<NonConformanceItem>));
  }

  /**
   * @param nonConformanceId undefined
   */
  getNonConformanceResponse(nonConformanceId: number): __Observable<__StrictHttpResponse<NonConformanceModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/QualityHistory/${encodeURIComponent(String(nonConformanceId))}/GetNonConformance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NonConformanceModel>;
      })
    );
  }
  /**
   * @param nonConformanceId undefined
   */
  getNonConformance(nonConformanceId: number): __Observable<NonConformanceModel> {
    return this.getNonConformanceResponse(nonConformanceId).pipe(__map((_r) => _r.body as NonConformanceModel));
  }

  /**
   * @param productionOrderId undefined
   */
  getNonConformance2Response(productionOrderId?: number): __Observable<__StrictHttpResponse<CreateNonConformance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (productionOrderId != null) __params = __params.set('productionOrderId', productionOrderId.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/QualityHistory/GetCreateNonConformance`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateNonConformance>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getNonConformance2(productionOrderId?: number): __Observable<CreateNonConformance> {
    return this.getNonConformance2Response(productionOrderId).pipe(__map((_r) => _r.body as CreateNonConformance));
  }
}

module QualityHistoryService {
  /**
   * Parameters for CheckQaHistory
   */
  export interface CheckQaHistoryParams {
    productionOrderId: number;
    entry: QaHistoryCheckEntry;
  }

  /**
   * Parameters for CreateNonConformance
   */
  export interface CreateNonConformanceParams {
    productionOrderId: number;
    entry: CreateNonConformanceEntry;
  }

  /**
   * Parameters for GetFinishedGoodArticles
   */
  export interface GetFinishedGoodArticlesParams {
    workCenterId: number;
    loadOnlyForActiveOrder: boolean;
  }

  /**
   * Parameters for GetNonConformanceOverview
   */
  export interface GetNonConformanceOverviewParams {
    workCenterId: number;
    articleId: number;
  }
}

export { QualityHistoryService };
