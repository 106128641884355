/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CoreApiConfiguration, CoreApiConfigurationInterface } from './core-api-configuration';

import { ApprovalReportService } from './services/approval-report.service';
import { ChangeNotificationsService } from './services/change-notifications.service';
import { ContainersService } from './services/containers.service';
import { DocumentManagementService } from './services/document-management.service';
import { DowntimesService } from './services/downtimes.service';
import { EmployeeRegistrationService } from './services/employee-registration.service';
import { FrontendConfigService } from './services/frontend-config.service';
import { MachineService } from './services/machine.service';
import { MachineScheduleService } from './services/machine-schedule.service';
import { MaintenanceService } from './services/maintenance.service';
import { MaterialService } from './services/material.service';
import { PackLineService } from './services/pack-line.service';
import { PatternInfoService } from './services/pattern-info.service';
import { PileTurnerService } from './services/pile-turner.service';
import { PrimaryMaterialService } from './services/primary-material.service';
import { ProducedMaterialService } from './services/produced-material.service';
import { ProductionOrderService } from './services/production-order.service';
import { ProductionOrderOutputStationService } from './services/production-order-output-station.service';
import { QaCheckService } from './services/qa-check.service';
import { QualityHistoryService } from './services/quality-history.service';
import { RemarkService } from './services/remark.service';
import { SecondaryMaterialService } from './services/secondary-material.service';
import { SettingsService } from './services/settings.service';
import { SetupParameterService } from './services/setup-parameter.service';
import { ShiftReportService } from './services/shift-report.service';
import { SimulationService } from './services/simulation.service';
import { TasksService } from './services/tasks.service';
import { TraceabilityService } from './services/traceability.service';
import { TraceMaterialService } from './services/trace-material.service';
import { VirtualContainerService } from './services/virtual-container.service';
import { WasteAssignmentService } from './services/waste-assignment.service';
import { WorkCenterService } from './services/work-center.service';
import { WorkCenterGroupService } from './services/work-center-group.service';

/**
 * Provider for all CoreApi services, plus CoreApiConfiguration
 */
@NgModule({
  imports: [HttpClientModule],
  exports: [HttpClientModule],
  declarations: [],
  providers: [
    CoreApiConfiguration,
    ApprovalReportService,
    ChangeNotificationsService,
    ContainersService,
    DocumentManagementService,
    DowntimesService,
    EmployeeRegistrationService,
    FrontendConfigService,
    MachineService,
    MachineScheduleService,
    MaintenanceService,
    MaterialService,
    PackLineService,
    PatternInfoService,
    PileTurnerService,
    PrimaryMaterialService,
    ProducedMaterialService,
    ProductionOrderService,
    ProductionOrderOutputStationService,
    QaCheckService,
    QualityHistoryService,
    RemarkService,
    SecondaryMaterialService,
    SettingsService,
    SetupParameterService,
    ShiftReportService,
    SimulationService,
    TasksService,
    TraceabilityService,
    TraceMaterialService,
    VirtualContainerService,
    WasteAssignmentService,
    WorkCenterService,
    WorkCenterGroupService
  ]
})
export class CoreApiModule {
  static forRoot(customParams: CoreApiConfigurationInterface): ModuleWithProviders<CoreApiModule> {
    return {
      ngModule: CoreApiModule,
      providers: [
        {
          provide: CoreApiConfiguration,
          useValue: { rootUrl: customParams.rootUrl }
        }
      ]
    };
  }
}
