<lib-container-list *ngIf="palletList?.length > 0" [mountedMaterials]="palletList">
  <lib-container-list-item
    *libItemContainer="let pallet"
    [gridColumnsWidths]="
      shouldAllowContainerActions(pallet) ? '100px 120px 120px 70px 70px 250px auto' : '100px 120px 120px 70px 70px 80px auto'
    "
  >
    <lib-pallet-number [palletNumber]="pallet.materialSequence" [classification]="pallet.article.classification"></lib-pallet-number>
    <div class="article-action">
      <div>
        {{ pallet.article?.externalArticleId }}
      </div>
      <div>
        {{ pallet.article?.externalConfigurationId }}
      </div>
    </div>
    <lib-pallet-kpi
      [compact]="true"
      [kpiLabel]="'MOUNTING.SSCC' | translate"
      [kpiValue]="pallet.ssccCode"
      [isSsccCodeShort]="true"
    ></lib-pallet-kpi>
    <div class="item-quantity">
      <span>
        {{ pallet.consumedQuantity | formatQuantity: false }}
      </span>
      <span class="item-unit">{{ pallet.consumedQuantity.unitId }}</span>
    </div>
    <div class="item-quantity">{{ pallet.mountedQuantity | formatQuantity: false }}</div>
    <lib-container-list-item-white-waste
      *ngIf="shouldAllowContainerActions(pallet); else whiteWasteCount"
      [item]="pallet"
      [addButtonLabel]="'MOUNTING.OK' | translate"
      [totalLabel]="'MOUNTING.TOTAL' | translate"
      (addWhiteWasteClicked)="onWhiteWasteAdd($event)"
      (resetWhiteWasteClicked)="resetPalletWhiteWaste($event)"
    ></lib-container-list-item-white-waste>
    <ng-template #whiteWasteCount>
      <div class="item-quantity">{{ pallet.whiteWasteQuantity | formatQuantity: false }}</div>
    </ng-template>
    <div class="item-actions">
      <lib-icon-button
        class="item-actions__dismount-status"
        [disabled]="shouldDismountBeDisabled(pallet)"
        [iconClass]="'icon-unmount-pallet'"
        (clicked)="onDismountClick(pallet)"
      ></lib-icon-button>
    </div>
  </lib-container-list-item>

  <lib-container-list-item-dummy *libDummyContainer="let pallet" [gridColumnsWidths]="'100px 120px 120px 70px 70px 250px auto'">
    <lib-pallet-number [palletNumber]="pallet.materialSequence" [classification]="pallet.article.classification"></lib-pallet-number>
    <div class="mount-pallet-list__article">
      <div class="article-action">
        <div>
          {{ pallet.article?.externalArticleId }}
        </div>
        <div>
          {{ pallet.article?.externalConfigurationId }}
        </div>
      </div>
    </div>
    <lib-pallet-kpi
      [compact]="true"
      [kpiLabel]="'MOUNTING.SSCC' | translate"
      [kpiValue]="pallet.ssccCode"
      [isSsccCodeShort]="true"
    ></lib-pallet-kpi>
    <div class="item-quantity">{{ pallet.consumedQuantity | formatQuantity: false }}</div>
    <div class="item-quantity">{{ pallet.mountedQuantity | formatQuantity: false }}</div>
    <div class="item-dummy">
      <lib-button
        class="item-dummy__button"
        [label]="'MOUNTING.REPLACE'"
        (clicked)="onReplaceClick(pallet.mountedMaterialId, pallet.container.containerId)"
      ></lib-button>
      <div class="item-dummy__wrapper">
        <i class="icon-warning-solid item-dummy__icon"></i>
        <div class="item-dummy__label">{{ 'MOUNTING.DUMMY' | translate }}</div>
      </div>
    </div>
    <div class="item-actions">
      <lib-icon-button
        class="item-actions__dismount-status"
        [disabled]="shouldDismountBeDisabled(pallet)"
        [iconClass]="'icon-unmount-pallet'"
        (clicked)="onDismountClick(pallet)"
      ></lib-icon-button>
    </div>
  </lib-container-list-item-dummy>
  <lib-container-list-item-empty *libEmptyContainer></lib-container-list-item-empty>

  <lib-container-list-materials *libMaterialBlock>
    <lib-mount-material-status [title]="isActiveOrder ? 'MOUNTING.ACTIVE_ORDER' : ''">
      <div>{{ productionOrderInfo?.externalProductionOrderId }}</div>
      <div>{{ article?.articleName }}</div>
      <div>{{ article?.externalArticleId }}</div>
      <div>{{ article?.externalConfigurationId }}</div>
    </lib-mount-material-status>
  </lib-container-list-materials>
</lib-container-list>
