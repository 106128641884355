/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PatternDetail } from '../models/pattern-detail';
@Injectable({
  providedIn: 'root'
})
class PatternInfoService extends __BaseService {
  static readonly getProductionOrderPatternInfoPath = '/api/v1/PatternInfo/ProductionOrder/{productionOrderId}/GetPatternInfo';
  static readonly isPatternAvailableForProductionOrderPath = '/api/v1/PatternInfo/ProductionOrder/{productionOrderId}/IsPatternAvailable';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param productionOrderId undefined
   */
  getProductionOrderPatternInfoResponse(productionOrderId: number): __Observable<__StrictHttpResponse<PatternDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/PatternInfo/ProductionOrder/${encodeURIComponent(String(productionOrderId))}/GetPatternInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PatternDetail>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getProductionOrderPatternInfo(productionOrderId: number): __Observable<PatternDetail> {
    return this.getProductionOrderPatternInfoResponse(productionOrderId).pipe(__map((_r) => _r.body as PatternDetail));
  }

  /**
   * @param productionOrderId undefined
   */
  isPatternAvailableForProductionOrderResponse(productionOrderId: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/PatternInfo/ProductionOrder/${encodeURIComponent(String(productionOrderId))}/IsPatternAvailable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  isPatternAvailableForProductionOrder(productionOrderId: number): __Observable<boolean> {
    return this.isPatternAvailableForProductionOrderResponse(productionOrderId).pipe(__map((_r) => _r.body as boolean));
  }
}

module PatternInfoService {}

export { PatternInfoService };
