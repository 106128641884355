import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputNumberModule } from 'primeng/inputnumber';
import { FileUploadModule } from 'primeng/fileupload';
import { DirectivesModule } from '../../directives';
import { DropdownComponent } from './dropdown/dropdown.component';
import { MultiSelectComponent } from './multiselect/multi-select.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';
import { ScanContainerComponent } from './scan-container/scan-container.component';
import { PalletQuantityCalculationComponent } from './pallet-quantity-calculation/pallet-quantity-calculation.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ChangeOrderQuantityComponent } from './change-order-quantity/change-order-quantity.component';
import { InputSwitchComponent } from './input-switch';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TranslateModule } from '@ngx-translate/core';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonComponent } from './button';
import { InputCheckComponent } from './input-check';
import { CheckboxModule } from 'primeng/checkbox';
import { FileUploadComponent } from './file-upload/file-upload.component';

@NgModule({
  declarations: [
    ModalFooterComponent,
    ScanContainerComponent,
    DropdownComponent,
    IconButtonComponent,
    PalletQuantityCalculationComponent,
    InputNumberComponent,
    CalendarComponent,
    InputSwitchComponent,
    ChangeOrderQuantityComponent,
    ButtonComponent,
    InputCheckComponent,
    MultiSelectComponent,
    FileUploadComponent
  ],
  exports: [
    ModalFooterComponent,
    ScanContainerComponent,
    DropdownComponent,
    IconButtonComponent,
    PalletQuantityCalculationComponent,
    InputNumberComponent,
    InputSwitchComponent,
    CalendarComponent,
    ChangeOrderQuantityComponent,
    ButtonComponent,
    InputCheckComponent,
    MultiSelectComponent,
    FileUploadComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    DropdownModule,
    CalendarModule,
    InputNumberModule,
    InputSwitchModule,
    ReactiveFormsModule,
    DirectivesModule,
    TranslateModule,
    InputTextModule,
    CheckboxModule,
    MultiSelectModule,
    FileUploadModule
  ]
})
export class FormComponentsModule {}
