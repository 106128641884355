/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AssignReasonToDowntimeEntry } from '../models/assign-reason-to-downtime-entry';
import { Downtime } from '../models/downtime';
import { AssignMultipleDownTimeEntry } from '../models/assign-multiple-down-time-entry';
import { SplitDowntimeEntry } from '../models/split-downtime-entry';
import { EditDowntimeEntry } from '../models/edit-downtime-entry';
import { RevertEditDowntimeEntry } from '../models/revert-edit-downtime-entry';
import { ConvertToProductionEntry } from '../models/convert-to-production-entry';
import { WorkCenterIdEntry } from '../models/work-center-id-entry';
import { DowntimeSection } from '../models/downtime-section';
import { DowntimeReason } from '../models/downtime-reason';
import { EditDowntimeStatus } from '../models/edit-downtime-status';
@Injectable({
  providedIn: 'root'
})
class DowntimesService extends __BaseService {
  static readonly assignReasonToDowntimePath = '/api/v1/Downtimes/{downtimeId}';
  static readonly getDowntimePath = '/api/v1/Downtimes/{downtimeId}';
  static readonly assignMultipleDowntimePath = '/api/v1/Downtimes';
  static readonly splitDowntimePath = '/api/v1/Downtimes/{downtimeId}/SplitDowntime';
  static readonly editDowntimePath = '/api/v1/Downtimes/{downtimeId}/EditDowntime';
  static readonly revertEditDowntimePath = '/api/v1/Downtimes/{downtimeId}/RevertEditDowntime';
  static readonly convertToProductionPath = '/api/v1/Downtimes/{downtimeId}/ConvertToProduction';
  static readonly cleanupPath = '/api/v1/Downtimes/{downtimeId}/Cleanup';
  static readonly getReasonsTreeListAllPath = '/api/v1/Downtimes/Reasons/{workCenterId}';
  static readonly getReasonsTreeListPath = '/api/v1/Downtimes/ReasonsTreeList/{workCenterId}';
  static readonly getReasonsFlatListPath = '/api/v1/Downtimes/ReasonsFlatList/{workCenterId}';
  static readonly getEditDowntimeStatusPath = '/api/v1/Downtimes/EditDowntimeStatus/{downtimeId}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `DowntimesService.AssignReasonToDowntimeParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `downtimeId`:
   */
  assignReasonToDowntimeResponse(params: DowntimesService.AssignReasonToDowntimeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.entry;

    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/v1/Downtimes/${encodeURIComponent(String(params.downtimeId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `DowntimesService.AssignReasonToDowntimeParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `downtimeId`:
   */
  assignReasonToDowntime(params: DowntimesService.AssignReasonToDowntimeParams): __Observable<null> {
    return this.assignReasonToDowntimeResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param downtimeId undefined
   */
  getDowntimeResponse(downtimeId: number): __Observable<__StrictHttpResponse<Downtime>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/Downtimes/${encodeURIComponent(String(downtimeId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Downtime>;
      })
    );
  }
  /**
   * @param downtimeId undefined
   */
  getDowntime(downtimeId: number): __Observable<Downtime> {
    return this.getDowntimeResponse(downtimeId).pipe(__map((_r) => _r.body as Downtime));
  }

  /**
   * @param entry undefined
   */
  assignMultipleDowntimeResponse(entry: AssignMultipleDownTimeEntry): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entry;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/v1/Downtimes`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entry undefined
   */
  assignMultipleDowntime(entry: AssignMultipleDownTimeEntry): __Observable<null> {
    return this.assignMultipleDowntimeResponse(entry).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `DowntimesService.SplitDowntimeParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `downtimeId`:
   */
  splitDowntimeResponse(params: DowntimesService.SplitDowntimeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.entry;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Downtimes/${encodeURIComponent(String(params.downtimeId))}/SplitDowntime`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `DowntimesService.SplitDowntimeParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `downtimeId`:
   */
  splitDowntime(params: DowntimesService.SplitDowntimeParams): __Observable<null> {
    return this.splitDowntimeResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `DowntimesService.EditDowntimeParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `downtimeId`:
   */
  editDowntimeResponse(params: DowntimesService.EditDowntimeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.entry;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Downtimes/${encodeURIComponent(String(params.downtimeId))}/EditDowntime`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `DowntimesService.EditDowntimeParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `downtimeId`:
   */
  editDowntime(params: DowntimesService.EditDowntimeParams): __Observable<null> {
    return this.editDowntimeResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `DowntimesService.RevertEditDowntimeParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `downtimeId`:
   */
  revertEditDowntimeResponse(params: DowntimesService.RevertEditDowntimeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.entry;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Downtimes/${encodeURIComponent(String(params.downtimeId))}/RevertEditDowntime`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `DowntimesService.RevertEditDowntimeParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `downtimeId`:
   */
  revertEditDowntime(params: DowntimesService.RevertEditDowntimeParams): __Observable<null> {
    return this.revertEditDowntimeResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `DowntimesService.ConvertToProductionParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `downtimeId`:
   */
  convertToProductionResponse(params: DowntimesService.ConvertToProductionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.entry;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Downtimes/${encodeURIComponent(String(params.downtimeId))}/ConvertToProduction`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `DowntimesService.ConvertToProductionParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `downtimeId`:
   */
  convertToProduction(params: DowntimesService.ConvertToProductionParams): __Observable<null> {
    return this.convertToProductionResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `DowntimesService.CleanupParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `downtimeId`:
   */
  cleanupResponse(params: DowntimesService.CleanupParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.entry;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/Downtimes/${encodeURIComponent(String(params.downtimeId))}/Cleanup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `DowntimesService.CleanupParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `downtimeId`:
   */
  cleanup(params: DowntimesService.CleanupParams): __Observable<null> {
    return this.cleanupResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param workCenterId undefined
   */
  getReasonsTreeListAllResponse(workCenterId: number): __Observable<__StrictHttpResponse<Array<DowntimeSection>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/Downtimes/Reasons/${encodeURIComponent(String(workCenterId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DowntimeSection>>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getReasonsTreeListAll(workCenterId: number): __Observable<Array<DowntimeSection>> {
    return this.getReasonsTreeListAllResponse(workCenterId).pipe(__map((_r) => _r.body as Array<DowntimeSection>));
  }

  /**
   * @param workCenterId undefined
   */
  getReasonsTreeListResponse(workCenterId: number): __Observable<__StrictHttpResponse<Array<DowntimeSection>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Downtimes/ReasonsTreeList/${encodeURIComponent(String(workCenterId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DowntimeSection>>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getReasonsTreeList(workCenterId: number): __Observable<Array<DowntimeSection>> {
    return this.getReasonsTreeListResponse(workCenterId).pipe(__map((_r) => _r.body as Array<DowntimeSection>));
  }

  /**
   * @param workCenterId undefined
   */
  getReasonsFlatListResponse(workCenterId: number): __Observable<__StrictHttpResponse<Array<DowntimeReason>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Downtimes/ReasonsFlatList/${encodeURIComponent(String(workCenterId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DowntimeReason>>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getReasonsFlatList(workCenterId: number): __Observable<Array<DowntimeReason>> {
    return this.getReasonsFlatListResponse(workCenterId).pipe(__map((_r) => _r.body as Array<DowntimeReason>));
  }

  /**
   * @param downtimeId undefined
   */
  getEditDowntimeStatusResponse(downtimeId: number): __Observable<__StrictHttpResponse<EditDowntimeStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Downtimes/EditDowntimeStatus/${encodeURIComponent(String(downtimeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EditDowntimeStatus>;
      })
    );
  }
  /**
   * @param downtimeId undefined
   */
  getEditDowntimeStatus(downtimeId: number): __Observable<EditDowntimeStatus> {
    return this.getEditDowntimeStatusResponse(downtimeId).pipe(__map((_r) => _r.body as EditDowntimeStatus));
  }
}

module DowntimesService {
  /**
   * Parameters for AssignReasonToDowntime
   */
  export interface AssignReasonToDowntimeParams {
    entry: AssignReasonToDowntimeEntry;
    downtimeId: number;
  }

  /**
   * Parameters for SplitDowntime
   */
  export interface SplitDowntimeParams {
    entry: SplitDowntimeEntry;
    downtimeId: number;
  }

  /**
   * Parameters for EditDowntime
   */
  export interface EditDowntimeParams {
    entry: EditDowntimeEntry;
    downtimeId: number;
  }

  /**
   * Parameters for RevertEditDowntime
   */
  export interface RevertEditDowntimeParams {
    entry: RevertEditDowntimeEntry;
    downtimeId: number;
  }

  /**
   * Parameters for ConvertToProduction
   */
  export interface ConvertToProductionParams {
    entry: ConvertToProductionEntry;
    downtimeId: number;
  }

  /**
   * Parameters for Cleanup
   */
  export interface CleanupParams {
    entry: WorkCenterIdEntry;
    downtimeId: number;
  }
}

export { DowntimesService };
