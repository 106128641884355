/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateContainerFromPoolEntry } from '../models/create-container-from-pool-entry';
import { PrintLabelEntry } from '../models/print-label-entry';
import { SetPrintLabelInfoEntry } from '../models/set-print-label-info-entry';
import { WorkCenterIdEntry } from '../models/work-center-id-entry';
import { AddWasteAssignmentToProducedMaterialEntry } from '../models/add-waste-assignment-to-produced-material-entry';
import { DeleteWasteAssignmentFromProducedMaterialEntry } from '../models/delete-waste-assignment-from-produced-material-entry';
import { ChangeContainerQuantityEntry } from '../models/change-container-quantity-entry';
import { ChangeContainerBobbinQuantityEntry } from '../models/change-container-bobbin-quantity-entry';
import { SetContainerTargetQuantityEntry } from '../models/set-container-target-quantity-entry';
import { SetContainerTargetBobbinQuantityEntry } from '../models/set-container-target-bobbin-quantity-entry';
import { SwitchManualProductionOrderEntry } from '../models/switch-manual-production-order-entry';
import { Container } from '../models/container';
import { ProducedMaterialsViewModel } from '../models/produced-materials-view-model';
import { CreateManualPalletData } from '../models/create-manual-pallet-data';
import { PrimaryMaterialMounted } from '../models/primary-material-mounted';
@Injectable({
  providedIn: 'root'
})
class ContainersService extends __BaseService {
  static readonly createContainerFromPoolPath = '/api/v1/Containers/{productionOrderId}/CreateFromPool';
  static readonly printLabelPath = '/api/v1/Containers/{containerId}/PrintLabel';
  static readonly setPrintLabelInfoPath = '/api/v1/Containers/{containerId}/SetPrintLabelInfo';
  static readonly finishProducedMaterialPath = '/api/v1/Containers/{producedMaterialId}/Finish';
  static readonly openProducedMaterialPath = '/api/v1/Containers/{producedMaterialId}/Open';
  static readonly setLastMarkPath = '/api/v1/Containers/{producedMaterialId}/SetLastMark';
  static readonly removeLastMarkPath = '/api/v1/Containers/{producedMaterialId}/RemoveLastMark';
  static readonly addWasteAssignmentToProducedMaterialPath = '/api/v1/Containers/{producedMaterialId}/AddWasteAssignmentToProducedMaterial';
  static readonly deleteWasteAssignmentFromProducedMaterialPath =
    '/api/v1/Containers/{producedMaterialId}/DeleteWasteAssignmentFromProducedMaterial';
  static readonly changeContainerQuantityPath = '/api/v1/Containers/{producedMaterialId}/ChangeContainerQuantity';
  static readonly changeContainerBobbinQuantityPath = '/api/v1/Containers/{producedMaterialId}/ChangeContainerBobbinQuantity';
  static readonly setContainerTargetQuantityPath = '/api/v1/Containers/{productionOrderId}/SetContainerTargetQuantity';
  static readonly setContainerTargetBobbinQuantityPath = '/api/v1/Containers/{productionOrderId}/SetContainerTargetBobbinQuantity';
  static readonly switchManualProductionOrderPath = '/api/v1/Containers/{productionOrderId}/SwitchManualProductionOrder';
  static readonly getContainerPath = '/api/v1/Containers/{containerId}';
  static readonly getProducedMaterialListPath = '/api/v1/Containers/{productionOrderId}/ProducedMaterials';
  static readonly getProducedOutputStationListPath =
    '/api/v1/Containers/productionOrder/{productionOrderId}/outputStation/{outputStationId}/ProducedOutputStations';
  static readonly getManualContainerCreationDataPath = '/api/v1/Containers/{productionOrderId}/GetManualContainerCreationData';
  static readonly getInputContainerListPath = '/api/v1/Containers/{workCenterId}/InputContainers';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `ContainersService.CreateContainerFromPoolParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  createContainerFromPoolResponse(params: ContainersService.CreateContainerFromPoolParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Containers/${encodeURIComponent(String(params.productionOrderId))}/CreateFromPool`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ContainersService.CreateContainerFromPoolParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  createContainerFromPool(params: ContainersService.CreateContainerFromPoolParams): __Observable<null> {
    return this.createContainerFromPoolResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ContainersService.PrintLabelParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `containerId`:
   */
  printLabelResponse(params: ContainersService.PrintLabelParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.entry;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Containers/${encodeURIComponent(String(params.containerId))}/PrintLabel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ContainersService.PrintLabelParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `containerId`:
   */
  printLabel(params: ContainersService.PrintLabelParams): __Observable<null> {
    return this.printLabelResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ContainersService.SetPrintLabelInfoParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `containerId`:
   */
  setPrintLabelInfoResponse(params: ContainersService.SetPrintLabelInfoParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.entry;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Containers/${encodeURIComponent(String(params.containerId))}/SetPrintLabelInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ContainersService.SetPrintLabelInfoParams` containing the following parameters:
   *
   * - `entry`:
   *
   * - `containerId`:
   */
  setPrintLabelInfo(params: ContainersService.SetPrintLabelInfoParams): __Observable<null> {
    return this.setPrintLabelInfoResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ContainersService.FinishProducedMaterialParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  finishProducedMaterialResponse(params: ContainersService.FinishProducedMaterialParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Containers/${encodeURIComponent(String(params.producedMaterialId))}/Finish`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ContainersService.FinishProducedMaterialParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  finishProducedMaterial(params: ContainersService.FinishProducedMaterialParams): __Observable<null> {
    return this.finishProducedMaterialResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ContainersService.OpenProducedMaterialParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  openProducedMaterialResponse(params: ContainersService.OpenProducedMaterialParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Containers/${encodeURIComponent(String(params.producedMaterialId))}/Open`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ContainersService.OpenProducedMaterialParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  openProducedMaterial(params: ContainersService.OpenProducedMaterialParams): __Observable<null> {
    return this.openProducedMaterialResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ContainersService.SetLastMarkParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  setLastMarkResponse(params: ContainersService.SetLastMarkParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Containers/${encodeURIComponent(String(params.producedMaterialId))}/SetLastMark`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ContainersService.SetLastMarkParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  setLastMark(params: ContainersService.SetLastMarkParams): __Observable<null> {
    return this.setLastMarkResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ContainersService.RemoveLastMarkParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  removeLastMarkResponse(params: ContainersService.RemoveLastMarkParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Containers/${encodeURIComponent(String(params.producedMaterialId))}/RemoveLastMark`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ContainersService.RemoveLastMarkParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  removeLastMark(params: ContainersService.RemoveLastMarkParams): __Observable<null> {
    return this.removeLastMarkResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ContainersService.AddWasteAssignmentToProducedMaterialParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  addWasteAssignmentToProducedMaterialResponse(
    params: ContainersService.AddWasteAssignmentToProducedMaterialParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Containers/${encodeURIComponent(String(params.producedMaterialId))}/AddWasteAssignmentToProducedMaterial`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ContainersService.AddWasteAssignmentToProducedMaterialParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  addWasteAssignmentToProducedMaterial(params: ContainersService.AddWasteAssignmentToProducedMaterialParams): __Observable<null> {
    return this.addWasteAssignmentToProducedMaterialResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ContainersService.DeleteWasteAssignmentFromProducedMaterialParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  deleteWasteAssignmentFromProducedMaterialResponse(
    params: ContainersService.DeleteWasteAssignmentFromProducedMaterialParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/api/v1/Containers/${encodeURIComponent(String(params.producedMaterialId))}/DeleteWasteAssignmentFromProducedMaterial`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ContainersService.DeleteWasteAssignmentFromProducedMaterialParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  deleteWasteAssignmentFromProducedMaterial(params: ContainersService.DeleteWasteAssignmentFromProducedMaterialParams): __Observable<null> {
    return this.deleteWasteAssignmentFromProducedMaterialResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ContainersService.ChangeContainerQuantityParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  changeContainerQuantityResponse(params: ContainersService.ChangeContainerQuantityParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Containers/${encodeURIComponent(String(params.producedMaterialId))}/ChangeContainerQuantity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ContainersService.ChangeContainerQuantityParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  changeContainerQuantity(params: ContainersService.ChangeContainerQuantityParams): __Observable<null> {
    return this.changeContainerQuantityResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ContainersService.ChangeContainerBobbinQuantityParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  changeContainerBobbinQuantityResponse(
    params: ContainersService.ChangeContainerBobbinQuantityParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Containers/${encodeURIComponent(String(params.producedMaterialId))}/ChangeContainerBobbinQuantity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ContainersService.ChangeContainerBobbinQuantityParams` containing the following parameters:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  changeContainerBobbinQuantity(params: ContainersService.ChangeContainerBobbinQuantityParams): __Observable<null> {
    return this.changeContainerBobbinQuantityResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ContainersService.SetContainerTargetQuantityParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  setContainerTargetQuantityResponse(params: ContainersService.SetContainerTargetQuantityParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Containers/${encodeURIComponent(String(params.productionOrderId))}/SetContainerTargetQuantity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ContainersService.SetContainerTargetQuantityParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  setContainerTargetQuantity(params: ContainersService.SetContainerTargetQuantityParams): __Observable<null> {
    return this.setContainerTargetQuantityResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ContainersService.SetContainerTargetBobbinQuantityParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  setContainerTargetBobbinQuantityResponse(
    params: ContainersService.SetContainerTargetBobbinQuantityParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Containers/${encodeURIComponent(String(params.productionOrderId))}/SetContainerTargetBobbinQuantity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ContainersService.SetContainerTargetBobbinQuantityParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  setContainerTargetBobbinQuantity(params: ContainersService.SetContainerTargetBobbinQuantityParams): __Observable<null> {
    return this.setContainerTargetBobbinQuantityResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `ContainersService.SwitchManualProductionOrderParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  switchManualProductionOrderResponse(
    params: ContainersService.SwitchManualProductionOrderParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Containers/${encodeURIComponent(String(params.productionOrderId))}/SwitchManualProductionOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ContainersService.SwitchManualProductionOrderParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  switchManualProductionOrder(params: ContainersService.SwitchManualProductionOrderParams): __Observable<null> {
    return this.switchManualProductionOrderResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param containerId undefined
   */
  getContainerResponse(containerId: number): __Observable<__StrictHttpResponse<Container>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/Containers/${encodeURIComponent(String(containerId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Container>;
      })
    );
  }
  /**
   * @param containerId undefined
   */
  getContainer(containerId: number): __Observable<Container> {
    return this.getContainerResponse(containerId).pipe(__map((_r) => _r.body as Container));
  }

  /**
   * @param params The `ContainersService.GetProducedMaterialListParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `loadingMode`:
   */
  getProducedMaterialListResponse(
    params: ContainersService.GetProducedMaterialListParams
  ): __Observable<__StrictHttpResponse<ProducedMaterialsViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.loadingMode != null) __params = __params.set('loadingMode', params.loadingMode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Containers/${encodeURIComponent(String(params.productionOrderId))}/ProducedMaterials`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProducedMaterialsViewModel>;
      })
    );
  }
  /**
   * @param params The `ContainersService.GetProducedMaterialListParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `loadingMode`:
   */
  getProducedMaterialList(params: ContainersService.GetProducedMaterialListParams): __Observable<ProducedMaterialsViewModel> {
    return this.getProducedMaterialListResponse(params).pipe(__map((_r) => _r.body as ProducedMaterialsViewModel));
  }

  /**
   * @param params The `ContainersService.GetProducedOutputStationListParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `outputStationId`:
   *
   * - `loadingMode`:
   */
  getProducedOutputStationListResponse(
    params: ContainersService.GetProducedOutputStationListParams
  ): __Observable<__StrictHttpResponse<ProducedMaterialsViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.loadingMode != null) __params = __params.set('loadingMode', params.loadingMode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/Containers/productionOrder/${encodeURIComponent(String(params.productionOrderId))}/outputStation/${encodeURIComponent(
          String(params.outputStationId)
        )}/ProducedOutputStations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProducedMaterialsViewModel>;
      })
    );
  }
  /**
   * @param params The `ContainersService.GetProducedOutputStationListParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `outputStationId`:
   *
   * - `loadingMode`:
   */
  getProducedOutputStationList(params: ContainersService.GetProducedOutputStationListParams): __Observable<ProducedMaterialsViewModel> {
    return this.getProducedOutputStationListResponse(params).pipe(__map((_r) => _r.body as ProducedMaterialsViewModel));
  }

  /**
   * @param productionOrderId undefined
   */
  getManualContainerCreationDataResponse(productionOrderId: number): __Observable<__StrictHttpResponse<CreateManualPalletData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Containers/${encodeURIComponent(String(productionOrderId))}/GetManualContainerCreationData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateManualPalletData>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getManualContainerCreationData(productionOrderId: number): __Observable<CreateManualPalletData> {
    return this.getManualContainerCreationDataResponse(productionOrderId).pipe(__map((_r) => _r.body as CreateManualPalletData));
  }

  /**
   * @param workCenterId undefined
   */
  getInputContainerListResponse(workCenterId: number): __Observable<__StrictHttpResponse<Array<PrimaryMaterialMounted>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Containers/${encodeURIComponent(String(workCenterId))}/InputContainers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PrimaryMaterialMounted>>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getInputContainerList(workCenterId: number): __Observable<Array<PrimaryMaterialMounted>> {
    return this.getInputContainerListResponse(workCenterId).pipe(__map((_r) => _r.body as Array<PrimaryMaterialMounted>));
  }
}

module ContainersService {
  /**
   * Parameters for CreateContainerFromPool
   */
  export interface CreateContainerFromPoolParams {
    productionOrderId: number;
    entry: CreateContainerFromPoolEntry;
  }

  /**
   * Parameters for PrintLabel
   */
  export interface PrintLabelParams {
    entry: PrintLabelEntry;
    containerId: number;
  }

  /**
   * Parameters for SetPrintLabelInfo
   */
  export interface SetPrintLabelInfoParams {
    entry: SetPrintLabelInfoEntry;
    containerId: number;
  }

  /**
   * Parameters for FinishProducedMaterial
   */
  export interface FinishProducedMaterialParams {
    producedMaterialId: number;
    entry: WorkCenterIdEntry;
  }

  /**
   * Parameters for OpenProducedMaterial
   */
  export interface OpenProducedMaterialParams {
    producedMaterialId: number;
    entry: WorkCenterIdEntry;
  }

  /**
   * Parameters for SetLastMark
   */
  export interface SetLastMarkParams {
    producedMaterialId: number;
    entry: WorkCenterIdEntry;
  }

  /**
   * Parameters for RemoveLastMark
   */
  export interface RemoveLastMarkParams {
    producedMaterialId: number;
    entry: WorkCenterIdEntry;
  }

  /**
   * Parameters for AddWasteAssignmentToProducedMaterial
   */
  export interface AddWasteAssignmentToProducedMaterialParams {
    producedMaterialId: number;
    entry: AddWasteAssignmentToProducedMaterialEntry;
  }

  /**
   * Parameters for DeleteWasteAssignmentFromProducedMaterial
   */
  export interface DeleteWasteAssignmentFromProducedMaterialParams {
    producedMaterialId: number;
    entry: DeleteWasteAssignmentFromProducedMaterialEntry;
  }

  /**
   * Parameters for ChangeContainerQuantity
   */
  export interface ChangeContainerQuantityParams {
    producedMaterialId: number;
    entry: ChangeContainerQuantityEntry;
  }

  /**
   * Parameters for ChangeContainerBobbinQuantity
   */
  export interface ChangeContainerBobbinQuantityParams {
    producedMaterialId: number;
    entry: ChangeContainerBobbinQuantityEntry;
  }

  /**
   * Parameters for SetContainerTargetQuantity
   */
  export interface SetContainerTargetQuantityParams {
    productionOrderId: number;
    entry: SetContainerTargetQuantityEntry;
  }

  /**
   * Parameters for SetContainerTargetBobbinQuantity
   */
  export interface SetContainerTargetBobbinQuantityParams {
    productionOrderId: number;
    entry: SetContainerTargetBobbinQuantityEntry;
  }

  /**
   * Parameters for SwitchManualProductionOrder
   */
  export interface SwitchManualProductionOrderParams {
    productionOrderId: number;
    entry: SwitchManualProductionOrderEntry;
  }

  /**
   * Parameters for GetProducedMaterialList
   */
  export interface GetProducedMaterialListParams {
    productionOrderId: number;
    loadingMode?: 'Open' | 'Finished' | 'All' | 'Update';
  }

  /**
   * Parameters for GetProducedOutputStationList
   */
  export interface GetProducedOutputStationListParams {
    productionOrderId: number;
    outputStationId: number;
    loadingMode?: 'Open' | 'Finished' | 'All' | 'Update';
  }
}

export { ContainersService };
