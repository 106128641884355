import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SupplyService } from '@pt/modules/pile-turner/services/supply/supply.service';
import { MaterialAvailableAtLocationItem, MaterialAvailableAtLocation } from 'chronos-core-client';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { notificationTopic } from '@pt/shared/utils';
import { LoadingNotificationService } from 'chronos-shared';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'pt-machine-location-modal',
  templateUrl: './machine-location-modal.component.html',
  styleUrls: ['./machine-location-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MachineLocationModalComponent implements OnInit {
  public readonly LOADING_TOPIC = notificationTopic.selectPalletAction;
  public availableForMount$: Observable<MaterialAvailableAtLocation>;

  constructor(private supplyService: SupplyService, private ref: DynamicDialogRef) {}

  public ngOnInit(): void {
    this.availableForMount$ = this.supplyService.getAvailableForMount();
  }

  public onSelectClick([selectedMaterial]: [MaterialAvailableAtLocationItem]): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    this.supplyService
      .scanPallet(selectedMaterial.ssccCode, selectedMaterial.containerId)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe((scannedPalletInfo) => {
        this.ref.close({ selectedPallet: scannedPalletInfo });
      });
  }
}
