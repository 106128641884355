/* tslint:disable */
type ProcessingStationType = 'None' | 'Score' | 'Crease' | 'Thread';
module ProcessingStationType {
  export const NONE: ProcessingStationType = 'None';
  export const SCORE: ProcessingStationType = 'Score';
  export const CREASE: ProcessingStationType = 'Crease';
  export const THREAD: ProcessingStationType = 'Thread';
  export function values(): ProcessingStationType[] {
    return [NONE, SCORE, CREASE, THREAD];
  }
}

export { ProcessingStationType };
