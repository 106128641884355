/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DirectMessageEntry } from '../models/direct-message-entry';
import { ClockInFilterDateEntry } from '../models/clock-in-filter-date-entry';
import { CheckProductionPeriodResponse } from '../models/check-production-period-response';
import { CheckProductionPeriodEntry } from '../models/check-production-period-entry';
import { StartProductionOrderInternalEntry } from '../models/start-production-order-internal-entry';
import { FinishProductionOrderInternalEntry } from '../models/finish-production-order-internal-entry';
import { ProductionOrderFillOutputStationBasedOnBOMEntry } from '../models/production-order-fill-output-station-based-on-bomentry';
@Injectable({
  providedIn: 'root'
})
class MaintenanceService extends __BaseService {
  static readonly sendMessagePath = '/api/v1/SendMessage';
  static readonly maintainMaterialBlocksPath = '/api/v1/{workCenterId}/MaintainMaterialBlocks';
  static readonly bookConsumptionPath = '/api/v1/{workCenterId}/BookConsumption';
  static readonly clockInFilterDatePath = '/api/v1/{workCenterId}/ClockInFilterDate';
  static readonly checkProductionPeriodPath = '/api/v1/{workCenterId}/RecalculateProductionPeriod';
  static readonly checkProductionPeriod2Path = '/api/v1/{workCenterId}/CheckProductionPeriod';
  static readonly startProductionOrderInternalPath = '/api/v1/{productionOrderId}/StartProductionOrderInternal';
  static readonly sendProductionOrderFinishedInternalPath = '/api/v1/{productionOrderId}/SendProductionOrderFinishedInternal';
  static readonly productionOrderFillOutputStationBasedOnBOMPath = '/api/v1/{productionOrderId}/ProductionOrderFillOutputStationBasedOnBOM';
  static readonly workCenterFillOutputStationsBasedOnBOMPath = '/api/v1/{workCenterId}/WorkCenterFillOutputStationsBasedOnBOM';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param entry undefined
   */
  sendMessageResponse(entry: DirectMessageEntry): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entry;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/v1/SendMessage`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entry undefined
   */
  sendMessage(entry: DirectMessageEntry): __Observable<null> {
    return this.sendMessageResponse(entry).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param workCenterId undefined
   */
  maintainMaterialBlocksResponse(workCenterId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/${encodeURIComponent(String(workCenterId))}/MaintainMaterialBlocks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  maintainMaterialBlocks(workCenterId: number): __Observable<null> {
    return this.maintainMaterialBlocksResponse(workCenterId).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param workCenterId undefined
   */
  bookConsumptionResponse(workCenterId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/v1/${encodeURIComponent(String(workCenterId))}/BookConsumption`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  bookConsumption(workCenterId: number): __Observable<null> {
    return this.bookConsumptionResponse(workCenterId).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `MaintenanceService.ClockInFilterDateParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  clockInFilterDateResponse(params: MaintenanceService.ClockInFilterDateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/${encodeURIComponent(String(params.workCenterId))}/ClockInFilterDate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `MaintenanceService.ClockInFilterDateParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  clockInFilterDate(params: MaintenanceService.ClockInFilterDateParams): __Observable<null> {
    return this.clockInFilterDateResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param workCenterId undefined
   */
  checkProductionPeriodResponse(workCenterId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/${encodeURIComponent(String(workCenterId))}/RecalculateProductionPeriod`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  checkProductionPeriod(workCenterId: number): __Observable<null> {
    return this.checkProductionPeriodResponse(workCenterId).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `MaintenanceService.CheckProductionPeriod2Params` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  checkProductionPeriod2Response(
    params: MaintenanceService.CheckProductionPeriod2Params
  ): __Observable<__StrictHttpResponse<CheckProductionPeriodResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/${encodeURIComponent(String(params.workCenterId))}/CheckProductionPeriod`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CheckProductionPeriodResponse>;
      })
    );
  }
  /**
   * @param params The `MaintenanceService.CheckProductionPeriod2Params` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `entry`:
   */
  checkProductionPeriod2(params: MaintenanceService.CheckProductionPeriod2Params): __Observable<CheckProductionPeriodResponse> {
    return this.checkProductionPeriod2Response(params).pipe(__map((_r) => _r.body as CheckProductionPeriodResponse));
  }

  /**
   * @param params The `MaintenanceService.StartProductionOrderInternalParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  startProductionOrderInternalResponse(
    params: MaintenanceService.StartProductionOrderInternalParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/${encodeURIComponent(String(params.productionOrderId))}/StartProductionOrderInternal`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `MaintenanceService.StartProductionOrderInternalParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  startProductionOrderInternal(params: MaintenanceService.StartProductionOrderInternalParams): __Observable<null> {
    return this.startProductionOrderInternalResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `MaintenanceService.SendProductionOrderFinishedInternalParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  sendProductionOrderFinishedInternalResponse(
    params: MaintenanceService.SendProductionOrderFinishedInternalParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/${encodeURIComponent(String(params.productionOrderId))}/SendProductionOrderFinishedInternal`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `MaintenanceService.SendProductionOrderFinishedInternalParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  sendProductionOrderFinishedInternal(params: MaintenanceService.SendProductionOrderFinishedInternalParams): __Observable<null> {
    return this.sendProductionOrderFinishedInternalResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `MaintenanceService.ProductionOrderFillOutputStationBasedOnBOMParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  productionOrderFillOutputStationBasedOnBOMResponse(
    params: MaintenanceService.ProductionOrderFillOutputStationBasedOnBOMParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/${encodeURIComponent(String(params.productionOrderId))}/ProductionOrderFillOutputStationBasedOnBOM`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `MaintenanceService.ProductionOrderFillOutputStationBasedOnBOMParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  productionOrderFillOutputStationBasedOnBOM(
    params: MaintenanceService.ProductionOrderFillOutputStationBasedOnBOMParams
  ): __Observable<null> {
    return this.productionOrderFillOutputStationBasedOnBOMResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param workCenterId undefined
   */
  workCenterFillOutputStationsBasedOnBOMResponse(workCenterId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/${encodeURIComponent(String(workCenterId))}/WorkCenterFillOutputStationsBasedOnBOM`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  workCenterFillOutputStationsBasedOnBOM(workCenterId: number): __Observable<null> {
    return this.workCenterFillOutputStationsBasedOnBOMResponse(workCenterId).pipe(__map((_r) => _r.body as null));
  }
}

module MaintenanceService {
  /**
   * Parameters for ClockInFilterDate
   */
  export interface ClockInFilterDateParams {
    workCenterId: number;
    entry: ClockInFilterDateEntry;
  }

  /**
   * Parameters for CheckProductionPeriod2
   */
  export interface CheckProductionPeriod2Params {
    workCenterId: number;
    entry: CheckProductionPeriodEntry;
  }

  /**
   * Parameters for StartProductionOrderInternal
   */
  export interface StartProductionOrderInternalParams {
    productionOrderId: number;
    entry: StartProductionOrderInternalEntry;
  }

  /**
   * Parameters for SendProductionOrderFinishedInternal
   */
  export interface SendProductionOrderFinishedInternalParams {
    productionOrderId: number;
    entry: FinishProductionOrderInternalEntry;
  }

  /**
   * Parameters for ProductionOrderFillOutputStationBasedOnBOM
   */
  export interface ProductionOrderFillOutputStationBasedOnBOMParams {
    productionOrderId: number;
    entry: ProductionOrderFillOutputStationBasedOnBOMEntry;
  }
}

export { MaintenanceService };
