import { Injectable } from '@angular/core';
import { FrontendFeatures, WorkCenter, WorkCenterQaHistoryParameter, WorkCenterState } from 'chronos-core-client';
import { AppSettingsStore } from './app-settings.store';

@Injectable({ providedIn: 'root' })
export class AppSettingsService {
  constructor(private appSettingsStore: AppSettingsStore) {}

  public setWorkCenter(workCenter: WorkCenter): void {
    this.appSettingsStore.update({ workCenter });
  }

  public setQaHistoryParameter(qaHistoryParameter: WorkCenterQaHistoryParameter): void {
    this.appSettingsStore.update({ qaHistoryParameter });
  }

  public setQaHistorySetup(isSetupQaHistoryPhaseEnabled: boolean): void {
    this.appSettingsStore.update({ isSetupQaHistoryPhaseEnabled });
  }

  public setQaChecksSetup(isSetupQaChecksPhaseEnabled: boolean): void {
    this.appSettingsStore.update({ isSetupQaChecksPhaseEnabled });
  }

  public setAppFeatures(features: FrontendFeatures): void {
    this.appSettingsStore.update({ features });
  }

  public setWorkCenterState(workCenterState: WorkCenterState): void {
    this.appSettingsStore.update({ workCenterState });
  }

  public setLineClearanceSetup(isSetupLineClearancePhaseEnabled: boolean): void {
    this.appSettingsStore.update({ isSetupLineClearancePhaseEnabled });
  }

  public setPatternInfoSetup(isSetupPatternInfoPhaseEnabled: boolean): void {
    this.appSettingsStore.update({ isSetupPatternInfoPhaseEnabled });
  }
}
